<template>
  <div class="main">
    <div class="tab_header" id="tab_header">
        <div class="lists" :class="'balance'" >
          <span class="data" @click="showDatePick = true">
            <font class="d3">{{monthList.year}}年{{monthList.month}}月</font>
            <div class="d4"></div>
          </span>
          <van-popup
            v-model="showDatePick"
            closeable
            position="bottom"
            :style="{ height: '400px' }"
          >
            <van-datetime-picker class="datetime-picker"
            style="margin-top:60px;"
              v-model="dataDate"
              closeable
              type="year-month"
              @confirm="changeData('confirm')"
            />
          </van-popup>
          <ul v-if="lists.length">
            <li v-for="(items, indexs) in lists" :key="indexs">
              <div class="left">
                <p>{{items.stateTime | clearTime}}</p>
                <p class="blue_font">当前余额：{{items.balance || 0}}元</p>
              </div>
              <div class="right">
                <p>+{{items.amount}}元</p>
                <p>充值金额</p>
              </div>
              <div class="bottom_div">{{items.longDesc}}</div>
            </li>
          </ul>
          <div v-else style="text-align: center;" class="tips_footer">
            <img :src="noMessage">
            <p>----该月份暂无数据----</p>
          </div>
        </div>
    </div>
    <!-- <div class="list_box">
      <van-list v-model="loading" :finished="finished" @load="scrollFetch()" class="content_box" v-if="lists.length">
        <van-cell v-for="(item, index) in lists" :key="index" @click="chooseShowActive(item, index)">
          <span>{{item.stateTime | clearTime}}</span>
          <span>{{item.amount}}元</span>
          <div v-if="(showActive == index) && (currentList.length)" class="detail">
            <div v-for="(d, i) in currentList" :key="i">
              <span>{{d.meterName}}</span>
              <span>{{d.amount}}元</span>
            </div>
          </div>
        </van-cell>
      </van-list>
      <p v-else>----该月份暂无数据----</p>
    </div> -->
  </div>
</template>

<script>
  import { userDetail } from '@/api/home'
  import api from '@/api/index'
  const { getRechargesInfor, fetchData } = api
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { getWxRecharge } from '@/api/wxSdk'
  import noMessage from '@/assets/home/no_message.png'
  import { userDB } from '@/db'
  export default {
    data() {
      return {
        userName: '',
        noMessage,
        userRemainder: '',
        companyName: '',
        tab_active: 0,
        pageCount: 0,
        loading: false,
        finished: true,
        showActive: -1,
        lists: [],
        monthList: {},
        bgnDate: `0`,
        endDate: `0`,
        showDatePick: false,
        dataDate: ``,
        currentList: []
      }
    },
    props: ['idName'],
    computed: {
      ...mapState(['user', 'global'])
    },
    filters: {
      clearTime(val) {
        if ((!!val) && (val.indexOf(' '))) {
          return val.split(' ')[0]
        }
        return val
      }
    },
    mounted() {
      this.$store.commit('SET_DATE')

      this.monthList = { year: this.global.currentYear, month: this.global.currentMonth }

      // 获取当前以及半年前日期
      this.bgnDate = `${this.global.currentYear}-${this.global.currentMonth}-${this.global.currentDay}`
      this.endDate = `${this.global.currentYear}-${this.global.currentMonth}-${this.global.currentDay}`

      this.dataDate = new Date()

      // 获取每月情况，暂时取消
      // this.bgnDate = `${this.global.currentYear}-${this.global.currentMonth}-01`
      // this.endDate = `${this.global.currentYear}-${this.global.currentMonth}-${this.global.currentDay}`

      this.gettlementDetails(`/running/recharges/vipUser/page`)
    },
    methods: {
      formatDays(val) {
        if (parseInt(val) < 10) {
          return `0${val}`
        } else {
          return val
        }
      },
      changeData(item = '1') {
        this.currentType = ''
        if (item === 'confirm') {
          this.monthList = { year: this.dataDate.getFullYear(), month: this.dataDate.getMonth() + 1 }
          this.gettlementDetails(`/running/recharges/vipUser/page`)
        }
        this.showDatePick = false
      },
      /*
        接口获取结算明细数据
        @parmas url(接口地址) index(改变的数据索引)
      */
      gettlementDetails(url) {
        // 调取接口函数
        const _self = this
        const date = this.dataDate
        this.dataDate = `${this.dataDate.getFullYear()}-${this.formatDays(this.dataDate.getMonth() + 1)}-01`
        const obj = {
          'dataDate': _self.dataDate,
          'page.pageNum': 1,
          'page.pageSize': 10
        }
        fetchData(obj, url, 'get').then(res => {
          this.lists = res.list
          this.dataDate = date
          // this.setMonthList()
        }).catch(err => {
          console.error('err', err)
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .main {
  }
  .tab_header {
    width:100%;
    float: left;
    font-size: 1rem;
    height: auto;
    overflow: auto;
    .lists {
      text-align: left;
      height: auto;
      min-height: 60px;
      line-height: 60px;
      color: #333;
      width: 100%;
      float: left;
      padding: 0;
      font-size: 1rem;
      overflow-x: hidden;
    }
    .data {
      display: inline-block;
      width: 90%;
      padding: 0.5rem 5%;
      background: #f2f2f2;
      float: left;
    }
    ul {
      width: 100%;
      background: #fff;
      height: auto;
      float: left;
      li:first-child {
        border-top: none;
      }
      li{
        border-top: 1px solid #f2f2f2;
        width: 90%;
        padding: 0 5%;
        height: auto;
        overflow: hidden;
        font-size: 0.8rem;
        color:#666;
        p {
          line-height: 2rem;
          margin: 1rem 0;
          margin-top: 0;
        }
        p:first-child {
          margin: 0.4rem 0;
        }
        .left {
          width: 50%;
          line-height:1rem;
          float: left;
          font-size: 1rem;
          color:#999;
          .blue_font {
            display: inline-block;
            width: auto;
            color: #66A6FF;
            border: 1px dashed #66A6FF;
            padding: 0 16px;
            font-size: 0.9rem;
          }
        }
        .right {
          width: 50%;
          line-height:1rem;
          text-align: right;
          font-size: 1rem;
          float: left;
          color: #000;
        }
      }
    }
    .tab_active {
      // color: red;
      // background: #fff;
    }
  }

  .tips_footer {
    width: 100%;
    margin-top: 10rem;
    font-size: 0.9rem;
    float: left;
    text-align: center;
    color: #666;
    img {
      width: 40%;
    }
  }
  .bottom_div {
    float: left;
    width: 100%;
    color: #999;
    word-break: break-all;
  }
  .detail {
    margin-top: 0.6rem;
    float: left;
    width: 100%;
    border-top: 1px solid #f7f7f7;
    padding-top: 0.4rem;
    div {
      float: left;
      width: 100%;
      height: 1.6rem;
      line-height: 1.6rem;
    }
  }

  .d3 {
    float: left;
  }

  .d4{
    margin-left: 0.6rem;
    float: left;
    width: 0;
    height: 0;
    border-width: 0.4rem;
    border-style: solid;
    border-color:#333 transparent transparent transparent;
    margin-top: 0.8rem;
  }

  .list_box {
    height: calc(100% - 100px);
    overflow: hidden;
    overflow: auto;
    p {
      text-align: center;
    }
  }
  .content_box {
    width:98%;
    margin: 0 1%;
    margin-top: 10px;
    height: 100%;
    overflow: auto;
    background: #fff;
    .top {
      border-left: 8px solid #4fb4e9;
      padding-left: 30px;
    }
    span {
      display: inline-block;
      width: 45%;
      float: left;
    }
    span:nth-child(3) {
      width: 10%;
    }
  }
</style>
