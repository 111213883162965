import request from '@/utils/request'

export function bannerFetch(query) {
  return request({
    url: '/market/adverts',
    method: 'get',
    params: query
  })
}

export function metersList() {
  return request({
    url: '/equipment/vip/meters',
    method: 'get'
  })
}

export function dayRead(id) {
  return request({
    url: '/api/dayRead/' + id,
    method: 'get'
  })
}

export function userDetail() {
  return request({
    url: '/archive/vip',
    method: 'get'
  })
}

export function getInfo() {
  return request({
    url: '/archive/vip',
    method: 'get'
  })
}
