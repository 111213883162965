<template>
  <div class="pay">
    <div class="topupbackg">
      <ul>
        <li>账户余额(元):</li>
        <li :class="user.userInfor.balance < 0 ? 'red_font' : 'blue_font'">
          {{ user.userInfor.balance }}
        </li>
      </ul>
    </div>

    <div class="title_tip">
      <span>充值金额</span>
    </div>

    <div class="btnBox">
      <van-row>
        <van-col
          span="8"
          style="text-align: center"
          v-for="(item, index) in btnArry"
          :key="index"
        >
          <van-button
            class="myBtn"
            type="default"
            :class="{ red_box: item == payMoney }"
            @click="clickBtn(item)"
            >{{ item }}</van-button
          >
        </van-col>
      </van-row>
    </div>
    <van-cell-group class="van_group">
      <van-field
        class="money_text"
        v-model="payMoney"
        placeholder="或输入充值金额"
        center
        clearable
      />
    </van-cell-group>
    <van-row type="flex" justify="center">
      <van-col span="22"
        ><van-button
          size="large"
          class="payBtn"
          disabled
          type="default"
          @click="recharge"
          >该功能暂未开放</van-button
        ></van-col
      >
    </van-row>
    <div
      class="footer_header"
      id="footer_header"
      @click="$router.push({ path: `/expensesPay` })"
    >
      <span class="right"><img :src="icon" /></span>查看充值明细
    </div>
    <expensesPay v-if="showLists" :idName = "idName"></expensesPay>
  </div>
</template>

<script>
import chongzhiDetail from '@/assets/home/chongzhi-detail.png'
import renovate from '@/assets/img/renovate.png'
import upIcon from '@/assets/home/up_rechage.png'
import downIcon from '@/assets/home/down.png'
import { userDetail } from '@/api/home'
import expensesPay from '@/views/expenses/expenses_pay.vue'
import api from '@/api/index'
// import wx from 'wx'
const { getWxConfig } = api
import { getWxRecharge } from '@/api/wxSdk'
import { mapState, mapActions, mapGetters } from 'vuex'
import { userDB } from '@/db'
//import { getAppid } from '@/api/getUrlParmas'
export default {
  data () {
    return {
      payMoney: null,
      showLists: false,
      btnArry: [50, 100, 150, 200, 300, 500],
      icon: upIcon,
      downIcon,
      chongzhiDetail,
      idName: ''
    }
  },
  computed: {
    ...mapState(['user'])
  },
  components: {
    expensesPay
  },
  created () {
  },
  mounted () {
    if (this.$route.query.id) {
      this.idName = this.$route.query.id

      // var anchor = document.getElementById(idName)
      // console.log(anchor.offsetTop)
      // document.body.scrollTop = anchor.offsetTop
    }
  },
  watch: {
    payMoney (curVal, oldVal) {
      if (
        curVal.toString().indexOf('.') > 0 &&
        curVal.toString().split('.')[1].length > 1
      ) {
        this.payMoney = oldVal
      }
    }
  },
  methods: {
    /*
      获取金额
      */
    clickBtn (obj) {
      this.payMoney = obj
    },
    // 刷新页面
    refresh () {
      const _self = this
      const appidName = userDB.get('appId')
      localStorage.clear()
      userDB.set('appId', appidName)
      _self.$store.commit('SET_APPID', 'wx5a503333e3a20439')
      _self.$store.dispatch('getWxPath').then(url => {
        location.href = decodeURIComponent(url)
      })
    },
    /*
      调用微信充值方法
    */
    recharge () {
      const _self = this
      let config
      if (!this.payMoney) {
        this.$toast('请输入金额')
        return
      }

      getWxConfig(_self.user.appId)
        .then(res => {
          config = {
            nonceStr: res.noncestr,
            timestamp: res.timestamp,
            appId: res.appId,
            signature: res.signature,
            jsApiList: ['chooseWXPay']
          }
          wx.config(config)
          return getWxRecharge(
            '/running/recharge/wechat/',
            _self.user.appId,
            _self.payMoney
          )
        })
        .then(res => {
          const result = res
          let token
          wx.ready(res => {
            wx.chooseWXPay({
              appId: result.appId,
              nonceStr: result.nonceStr,
              package: result.package,
              paySign: result.sign,
              signType: result.signType,
              timestamp: result.timeStamp,
              success: function (mes) {
                _self.$toast('成功')
                const balance = _self.user.userInfor.balance - _self.payMoney
                _self.$store.commit('SET_USERINFOR', { ..._self.user.userInfor, balance })
                setTimeout(() => {
                  // _self.refresh();
                  // return;

                  userDetail()
                    .then(message => {
                      // 支付完成后页面跳转
                      _self.$store.commit('SET_USERINFOR', message)
                      _self.$router.push({ name: 'home' })
                    })
                    .catch(err => {
                      console.log('chooseWXPay', err)
                    })
                }, 1500)
              },
              catch (err) {
                console.log('err', err)
              }
            })
          })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.van_group {
  background: none;
}
.pay {
  background: #fff;
}
.footer_header {
  width: 90%;
  padding: 0 5%;
  height: 2rem;
  line-height: 2rem;
  background: #fff;
  font-size: 0.8rem;
  text-align: center;
  .right {
    display: inline-block;
    width: 2rem;
    height: 3rem;
    line-height: 3rem;
    img {
      height: 0.8rem;
    }
  }
}
.title_tip {
  width: 100%;
  height: 1.3rem;
  line-height: 1.3rem;
  margin: 1rem 0;
  float: left;
  span {
    display: inline-block;
    line-height: 1.3rem;
    height: 1.3rem;
    margin-left: 5%;
    color: #333;
  }
}
.red_font {
  color: #d85252;
}
.btnBox {
  margin: 20px auto;
  .red_box {
    background: #66a6ff;
    color: #fff;
    border: 1px solid #66a6ff;
  }
}
.money_text {
  width: 90%;
  margin-left: 5%;
  margin-bottom: 2rem;
  border-bottom: 1px solid #999;
  background: none;
  font-size: 1rem;
}
.myBtn {
  width: 6rem;
  height: 4rem;
  font-size: 1.1rem;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  border: 1px solid #cdcdcd;
  border-radius: 15px;
  background: #fff;
}
.myBtn:active {
  background: #fff;
}
.payBtn {
  background-color: #00A39B;
  border-radius: 70px;
  color: #fff;
  margin-bottom: 0.5rem;
  margin-top: 2.5rem;
}
a {
  display: inline-block;
  width: 100%;
  font-size: 0.9rem;
  text-align: center;
  height: 2.2rem;
  color: #555;
  line-height: 2.2rem;
}
.topupbackg {
  width: 100%;
  height: auto;
  min-height: 4rem;
  overflow: hidden;
  border-bottom: 20px solid #e5e5e5;
  ul {
    width: 90%;
    margin: 0 5%;
    color: #333;
    float: left;
    font-size: 1rem;
    line-height: 4rem;
    text-align: center;
  }
  .red_font {
    color: #d85252;
  }
  .blue_font {
    color: #66a6ff;
  }
  li {
    float: left;
    width: 50%;
    text-align: right;
    font-size: 1.4rem;
  }
  li:first-child {
    text-align: left;
    font-size: 1rem;
    color: #333;
  }
}
</style>
